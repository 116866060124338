import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PhotoService } from 'src/app/services/photo.service';
import { Photo } from 'src/app/models/photo';
import { ThemeService } from 'src/app/services/theme.service';
import { Theme } from 'src/app/models/theme';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {

  themeId: string;
  theme: Theme;
  photos: Array<Photo>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private photoService: PhotoService,
    private urlService: UrlService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.themeId = params.theme.split('-')[0];

      this.themeService
        .get(this.themeId)
        .subscribe((theme) => this.theme = theme);

      this.photoService
        .getByThemeId(this.themeId)
        .subscribe((photos) => this.photos = photos);
    });
  }

  getThumbUrl(photo: Photo): string {
    return this.photoService.getThumbUrl(photo);
  }

  getPhotoUrl(photo: Photo): string {
    return this.urlService.getUrlNormalizedString(`${photo.id}-${photo.nom}`);
  }

}
