import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ContactMailService } from 'src/app/services/contact-mail.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  from: string;
  subject: string;
  body: string;

  fromFormControl: FormControl;
  subjectFormControl: FormControl;
  bodyFormControl: FormControl;
  formGroup: FormGroup;

  isEmailSent = false;

  constructor(
    private contactMailService: ContactMailService
  ) { }

  ngOnInit() {
    this.fromFormControl = new FormControl('', Validators.email);
    this.subjectFormControl = new FormControl('', Validators.required);
    this.bodyFormControl = new FormControl('', Validators.minLength(10));
    this.formGroup = new FormGroup({
      'from': this.fromFormControl,
      'subject': this.subjectFormControl,
      'body': this.bodyFormControl
    });
  }

  sendContactEmail() {
    this.contactMailService
      .sendContactEMail(this.fromFormControl.value, this.subjectFormControl.value, this.bodyFormControl.value)
      .subscribe(response => {
        this.isEmailSent = true;
      });
  }

  getSendButtonText(): string {
    return this.formGroup.valid
      ? 'ENVOYER LE MESSAGE'
      : 'VEUILLEZ REMPLIR TOUS LES CHAMPS';
  }
}
