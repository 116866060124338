import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ThemesComponent } from './components/themes/themes.component';
import { WhoAmIComponent } from './components/who-am-i/who-am-i.component';
import { ThemeComponent } from './components/theme/theme.component';
import { PhotoComponent } from './components/photo/photo.component';
import { PressComponent } from './components/press/press.component';
import { SearchComponent } from './components/search/search.component';
import { ContactComponent } from './components/contact/contact.component';
import { SearchDetailComponent } from './components/search-detail/search-detail.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { homePage: true, hideTopMenu: true } },
  { path: 'intro', component: WhoAmIComponent },
  { path: 'photos', component: ThemesComponent, data: { fullWidth: true } },
  // { path: 'photos/recherche/:motsCles', component: SearchComponent },
  // { path: 'photos/recherche/:motsCles/:photo', component: SearchDetailComponent },
  { path: 'photos/:theme', component: ThemeComponent, data: { fullWidth: true } },
  { path: 'photos/:theme/:photo', component: PhotoComponent, data: { hideTopMenu: true, fullWidth: true } },
  { path: 'presse', component: PressComponent },
  { path: 'recherche', component: SearchComponent },
  { path: 'contact', component: ContactComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
