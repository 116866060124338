import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';

import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  currentDate = Date.now();
  displayTopMenu = false;
  isHomePage = false;
  isFullWidth = false;
  backgroundId: string;

  BACKGROUND_COOKIE_ID = 'BACKGROUND_COOKIE_ID';
  LASTVISIT_COOKIE_ID = 'LASTVISIT_COOKIE_ID';

  constructor(
    private router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.isHomePage = data.state.root.firstChild.data.homePage;
        this.isFullWidth = data.state.root.firstChild.data.fullWidth;
        this.displayTopMenu = !data.state.root.firstChild.data.hideTopMenu;
      }
    });

    this.setBackground();
  }

  setBackground(): void {
    // Background Cookie
    let background = this.cookieService.get(this.BACKGROUND_COOKIE_ID);
    if (!background) {
      // Create the cookie, use background #1
      this.cookieService.set(this.BACKGROUND_COOKIE_ID, '1', 2629800);
      background = this.cookieService.get(this.BACKGROUND_COOKIE_ID);
    }

    // Last Visit Cookie
    let lastVisit = this.cookieService.get(this.LASTVISIT_COOKIE_ID);
    if (!lastVisit) {
      // Create the cookie, set current datetime
      this.cookieService.set(this.LASTVISIT_COOKIE_ID, moment().unix().toString(), 2629800);
      lastVisit = this.cookieService.get(this.LASTVISIT_COOKIE_ID);
    }

    try {
      const lastVisitPlus30Minutes = moment.unix(parseInt(lastVisit, 10)).add(30, 'minutes');
      if (moment().diff(lastVisitPlus30Minutes) > 0) {
        // Too old, we change the background image
        const newBackground = (parseInt(background, 10) % 3) + 1;
        this.cookieService.set(this.BACKGROUND_COOKIE_ID, newBackground.toString(), 2629800);
        this.backgroundId = newBackground.toString();
      }
    }
    catch (error) {
      this.cookieService.set(this.BACKGROUND_COOKIE_ID, '1', 2629800);
      this.backgroundId = '1';
    }
    finally {
      // Save the current datetime as last visit
      this.cookieService.set(this.LASTVISIT_COOKIE_ID, moment().unix().toString(), 2629800);
    }
  }

  getBackgroundCssClass(): string {
    return `bg-${this.backgroundId}`;
  }

}
