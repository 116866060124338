import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';

import { Theme, ThemeDb } from '../models/theme';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    private afs: AngularFirestore
  ) { }

  get(themeId: string): Observable<Theme> {
    return Observable.create((observer) => {
      this.afs
        .doc<ThemeDb>(`themes/${themeId}`)
        .valueChanges()
        .subscribe((theme) => {
          const result: Theme = {
            id: themeId,
            nom: theme.nom,
            ordre: theme.ordre,
            regroupement: theme.regroupement,
          };

          observer.next(result);
        });
    });
  }

  getThumbUrl(theme: Theme): string {
    return environment.themeThumbUrl.replace('{0}', theme.id);
  }

}
