import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PhotoService } from 'src/app/services/photo.service';
import { Photo, PhotoWithPreviousAndNext } from 'src/app/models/photo';
import { UrlService } from 'src/app/services/url.service';

enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

  photoId: string;
  themeUrlParam: string;
  themeId: string;
  photo: Photo;
  previousPhoto: Photo;
  nextPhoto: Photo;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private photoService: PhotoService,
    private urlService: UrlService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.photoId = params.photo.split('-')[0];
      this.themeUrlParam = params.theme;
      this.themeId = params.theme.split('-')[0];
      this.photoService
        .getWithPreviousAndNext(this.photoId)
        .subscribe((photoWithPreviousAndNext) => {
          this.photo = photoWithPreviousAndNext.photo;
          this.previousPhoto = photoWithPreviousAndNext.previousPhoto;
          this.nextPhoto = photoWithPreviousAndNext.nextPhoto;
        });
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.router.navigate(['', 'photos', this.themeId, this.previousPhoto.id]);
    }
    if (event.key === 'ArrowRight') {
      this.router.navigate(['', 'photos', this.themeId, this.nextPhoto.id]);
    }
  }

  getFullSizeUrl(photo: Photo): string {
    return this.photoService.getFullSizeUrl(photo);
  }

  getPhotoUrl(photo: Photo): string {
    return this.urlService.getUrlNormalizedString(`${photo.id}-${photo.nom}`);
  }

}
