// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA2HclQ2-ZgTUBPGsDJY28qz4c8Mhyu2Nw',
    authDomain: 'mbu-prod.firebaseapp.com',
    databaseURL: 'https://mbu-prod.firebaseio.com',
    projectId: 'mbu-prod',
    storageBucket: 'mbu-prod.appspot.com',
    messagingSenderId: '267789108727'
  },
  themeThumbUrl: 'https://firebasestorage.googleapis.com/v0/b/mbu-prod.appspot.com/o/themethumbs%2F{0}.jpg?alt=media',
  photoThumbUrl: 'https://firebasestorage.googleapis.com/v0/b/mbu-prod.appspot.com/o/photothumbs%2F{0}.jpg?alt=media',
  photoUrl: 'https://firebasestorage.googleapis.com/v0/b/mbu-prod.appspot.com/o/photos%2F{0}.jpg?alt=media',
  revuePresseUrl: 'https://firebasestorage.googleapis.com/v0/b/mbu-prod.appspot.com/o/revuespresse%2F{0}?alt=media'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
