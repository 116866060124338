import { Component, OnInit } from '@angular/core';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { PressService } from 'src/app/services/press.service';
import { RevuePresse } from 'src/app/models/revue-presse';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {

  faFilePdf = faFilePdf;
  revuesPresse: Array<RevuePresse>;

  constructor(
    private pressService: PressService
  ) { }

  ngOnInit() {
    this.pressService
      .getAll()
      .subscribe(revuesPresse => this.revuesPresse = revuesPresse);
  }

  getFileUrl(revuePresse: RevuePresse): string {
    return this.pressService.getFileUrl(revuePresse);
  }

}
