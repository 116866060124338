import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PhotoService } from 'src/app/services/photo.service';
import { Photo } from 'src/app/models/photo';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  keywords: string;
  photos: Array<Photo>;

  constructor(
    private photoService: PhotoService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute
      .params
      .subscribe(params => {
        this.keywords = params['motsCles'];
        this.photoService
          .getByKeyword(this.keywords)
          .subscribe(photos => {
            this.photos = photos;
          });
      });
  }

  getThumbUrl(photo: Photo): string {
    return this.photoService.getThumbUrl(photo);
  }

}
