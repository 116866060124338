import { Injectable } from '@angular/core';

import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class ContactMailService {

  constructor(
    private fns: AngularFireFunctions
  ) { }

  sendContactEMail(from: string, subject: string, body: string) {
    return this.fns
      .httpsCallable('sendContactEmail')(
        {
          from: from,
          subject: subject,
          body: body
        }
      );
  }

}
