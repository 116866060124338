import { Injectable } from '@angular/core';

import { Observable, of, zip, merge, forkJoin } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';

import { Photo, PhotoDb, PhotoWithPreviousAndNext } from '../models/photo';
import { SearchResult } from '../models/search-result';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  private searchResultsCache = new Array<SearchResult>();

  constructor(
    private afs: AngularFirestore
  ) { }

  getByThemeId(themeId: string): Observable<Array<Photo>> {
    return Observable.create((observer) => {
      this.afs
        .collection<PhotoDb>('photos', x => x.where('theme', '==', themeId).orderBy('ordre'))
        .snapshotChanges()
        .subscribe((photos) => {
          const result = new Array<Photo>();
          photos.forEach((photo) => {
            result.push({
              id: photo.payload.doc.id,
              nom: photo.payload.doc.data().nom,
              ordre: photo.payload.doc.data().ordre,
              commentaire: photo.payload.doc.data().commentaire,
              theme: photo.payload.doc.data().theme,
              motsCles: photo.payload.doc.data().motsCles
            });
          });
          observer.next(result);
        });
    });
  }

  getWithPreviousAndNext(photoId: string): Observable<PhotoWithPreviousAndNext> {
    return Observable.create((observer) => {
      this.afs
        .doc<PhotoDb>(`photos/${photoId}`)
        .valueChanges()
        .subscribe((photo) => {
          const result: PhotoWithPreviousAndNext = new PhotoWithPreviousAndNext();

          result.photo = {
            id: photoId,
            nom: photo.nom,
            ordre: photo.ordre,
            commentaire: photo.commentaire,
            theme: photo.theme,
            motsCles: photo.motsCles
          };

          const previous = this.afs
            .collection<PhotoDb>('photos', x =>
              x.where('theme', '==', photo.theme)
                .where('ordre', '<', photo.ordre)
                .orderBy('ordre', 'desc')
                .limit(1)
            ).snapshotChanges();

          const next = this.afs
            .collection<PhotoDb>('photos', x =>
              x.where('theme', '==', photo.theme)
                .where('ordre', '>', photo.ordre)
                .orderBy('ordre', 'asc')
                .limit(1)
            ).snapshotChanges();

          zip(previous, next).subscribe(zipResult => {
            const previousPhotoDb = zipResult[0][0];
            const nextPhotoDb = zipResult[1][0];

            if (previousPhotoDb) {
              result.previousPhoto = {
                id: previousPhotoDb.payload.doc.id,
                nom: previousPhotoDb.payload.doc.data().nom,
                ordre: previousPhotoDb.payload.doc.data().ordre,
                commentaire: previousPhotoDb.payload.doc.data().commentaire,
                theme: previousPhotoDb.payload.doc.data().theme,
                motsCles: previousPhotoDb.payload.doc.data().motsCles
              };
            }

            if (nextPhotoDb) {
              result.nextPhoto = {
                id: nextPhotoDb.payload.doc.id,
                nom: nextPhotoDb.payload.doc.data().nom,
                ordre: nextPhotoDb.payload.doc.data().ordre,
                commentaire: nextPhotoDb.payload.doc.data().commentaire,
                theme: nextPhotoDb.payload.doc.data().theme,
                motsCles: nextPhotoDb.payload.doc.data().motsCles
              };
            }

            observer.next(result);
          });
        });
    });
  }

  getThumbUrl(photo: Photo): string {
    return environment.photoThumbUrl.replace('{0}', photo.id);
  }

  getFullSizeUrl(photo: Photo): string {
    return environment.photoUrl.replace('{0}', photo.id);
  }

  getByKeyword = (keywords: string): Observable<Array<Photo>> => {
    return Observable.create((observer) => {

      const keywordsNormalized = this.normalizeTextForSearch(keywords);

      this.getSearchResultFromCache(keywordsNormalized)
        .subscribe(photos => {
          observer.next(photos);
          observer.complete();
        });

    });
  }

  private getSearchResultFromCache(keywords: Array<string>): Observable<Array<Photo>> {
    return Observable.create((observer) => {

      // Get from cache

      const searchResult = _.find(this.searchResultsCache, x => this.arraysEqual(x.keywords, keywords));
      console.log('beuh', searchResult)
      if (searchResult) {
        observer.next(searchResult.photos);
        observer.complete();
      }
      else {
        // Get from Db, then save to cache
        this.getByKeywordFromDb(keywords)
          .subscribe(photos => observer.next(photos));
      }
    });
  }

  private getByKeywordFromDb(keywords: Array<string>): Observable<Array<Photo>> {
    return Observable.create((observer) => {

      const searchFunction = (keyword) => {
        return Observable.create(observer2 => {
          this.afs
            .collection<PhotoDb>('photos', ref => ref.where('motsCles', 'array-contains', keyword).limit(50))
            .snapshotChanges()
            .subscribe((photos) => {
              const result = new Array<Photo>();
              photos.forEach((photo) => {
                result.push({
                  id: photo.payload.doc.id,
                  nom: photo.payload.doc.data().nom,
                  ordre: photo.payload.doc.data().ordre,
                  commentaire: photo.payload.doc.data().commentaire,
                  theme: photo.payload.doc.data().theme,
                  motsCles: photo.payload.doc.data().motsCles
                });
              });
              observer2.next(result);
              observer2.complete();
            });
        });
      };

      const obsArray = [];
      keywords.forEach((keyword) => {
        obsArray.push(searchFunction(keyword));
      });

      forkJoin(obsArray)
        .subscribe(values => {
          // Get all results
          const concatenatedValues = new Array<Photo>();
          values.forEach(value => {
            value.forEach(item => {
              concatenatedValues.push(item);
            });
          });
          // AND filter
          const result =
            _.filter(concatenatedValues, (x: Photo) => {
              let containsAll = true;
              keywords.forEach((normalizedText) => {
                if (!x.motsCles.includes(normalizedText)) {
                  containsAll = false;
                }
              });
              return containsAll;
            });

          // Save results to cache
          this.saveSearchResultInCache(keywords, result);

          observer.next(result);
          observer.complete();
        });

    });
  }

  private saveSearchResultInCache(keywords: Array<string>, photos: Array<Photo>): void {
    // // Sort arrays to keep consistency in cache
    // keywords = _.sortBy(keywords);
    // photos = _.sortBy(photos, [x => x.id]);

    const searchResult: SearchResult = {
      keywords: keywords,
      photos: photos
    };

    // Save to cache if not present yet
    if (!this.searchResultsCache.includes(searchResult)) {
      this.searchResultsCache.push(searchResult);
    }
  }

  normalizeTextForSearch(text: string): Array<string> {
    if (text.length === 0) {
      return [];
    }
    const normalizedArray = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/\W/g, ' ')
      .split(' ');

    _.remove(normalizedArray, x => x === '');
    return _.sortBy(_.uniq(normalizedArray));
  }

  private arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {

      return false;
    }
    for (let i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i]) {

        return false;
      }
    }

    return true;
  }

}
