import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';

import { Regroupement, RegroupementDb } from '../models/regroupement';
import { Theme, ThemeDb } from '../models/theme';

@Injectable({
  providedIn: 'root'
})
export class RegroupementService {

  constructor(
    private afs: AngularFirestore
  ) { }

  getAll(): Observable<Array<Regroupement>> {
    return Observable.create((observer) => {
      this.afs
        .collection<RegroupementDb>('regroupements', x => x.orderBy('ordre'))
        .snapshotChanges()
        .subscribe((regroupements) => {

          this.afs
            .collection<ThemeDb>('themes', x => x.orderBy('ordre'))
            .snapshotChanges()
            .subscribe((themes) => {

              const tempThemes = new Array<Theme>();
              themes
                .forEach((theme) => {
                  tempThemes.push({
                    id: theme.payload.doc.id,
                    nom: theme.payload.doc.data().nom,
                    ordre: theme.payload.doc.data().ordre,
                    regroupement: theme.payload.doc.data().regroupement,
                  });
                });

              const result = new Array<Regroupement>();
              regroupements
                .forEach((regroupement) => {
                  result.push({
                    id: regroupement.payload.doc.id,
                    nom: regroupement.payload.doc.data().nom,
                    ordre: regroupement.payload.doc.data().ordre,
                    isCollapsed: true,
                    themes: _.filter(tempThemes, x => x.regroupement.toString() === regroupement.payload.doc.id)
                  });
                });
              observer.next(result);

            });

        });
    });
  }

}
