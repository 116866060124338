import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

import { RevuePresse, RevuePresseDb } from '../models/revue-presse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PressService {

  constructor(
    private afs: AngularFirestore
  ) { }

  getAll(): Observable<Array<RevuePresse>> {
    return Observable.create((observer) => {
      this.afs
        .collection<RevuePresseDb>('revuesPresse', x => x.orderBy('ordre'))
        .snapshotChanges()
        .subscribe((revuesPresse) => {

          const result = new Array<RevuePresse>();
          revuesPresse
            .forEach((revuePresse) => {
              result.push({
                id: revuePresse.payload.doc.id,
                dateParution: revuePresse.payload.doc.data().dateParution,
                description: revuePresse.payload.doc.data().description,
                magazine: revuePresse.payload.doc.data().magazine,
                nomFichier: revuePresse.payload.doc.data().nomFichier,
                ordre: revuePresse.payload.doc.data().ordre,
              });
            });

          observer.next(result);
        });
    });
  }

  getFileUrl(revuePresse: RevuePresse): string {
    return environment.revuePresseUrl.replace('{0}', revuePresse.nomFichier);
  }

}
