import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faSearch, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

import { Regroupement } from 'src/app/models/regroupement';
import { RegroupementService } from 'src/app/services/regroupement.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Theme } from 'src/app/models/theme';
import { PhotoService } from 'src/app/services/photo.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss']
})
export class ThemesComponent implements OnInit {

  faSearch = faSearch;
  faAngleDoubleDown = faAngleDoubleDown;
  regroupements: Array<Regroupement>;
  motCle: string;

  constructor(
    private regroupementService: RegroupementService,
    private themeService: ThemeService,
    private photoService: PhotoService,
    private urlService: UrlService,
    private router: Router
  ) { }

  ngOnInit() {
    this.regroupementService
      .getAll()
      .subscribe((regroupements) => this.regroupements = regroupements);
  }

  uncollapseRegroupement(regroupement: Regroupement): void {
    regroupement.isCollapsed = false;
  }

  getThumbUrl(theme: Theme): string {
    return this.themeService.getThumbUrl(theme);
  }

  navigateToSearch(): void {
    this.router.navigate(['photos', 'recherche', this.motCle]);
  }

  getThemeUrl(theme: Theme): string {
    return this.urlService.getUrlNormalizedString(`${theme.id}-${theme.nom}`);
  }

}
