export class PhotoDb {
    nom: string;
    commentaire: string;
    ordre: number;
    theme: string;
    motsCles: Array<string>;
}

export class Photo extends PhotoDb {
    id: string;
}

export class PhotoWithPreviousAndNext {
    photo: Photo;
    previousPhoto: Photo;
    nextPhoto: Photo;
}
